import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/workspace/inbox-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "invoice-payments"
    }}>{`Invoice Payments`}</h1>
    <p>{`Invoice Payments can be linked to payment reasons (see features/payment_reasons) via Invoice Payment Payment Reasons.
These are used to explain the context of a payment to an invoice.`}</p>
    <h2 {...{
      "id": "model"
    }}>{`Model`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`InvoicePayments`}</inlineCode>{`:`}<ul parentName="li">
          <li parentName="ul">{`Belong to an `}<inlineCode parentName="li">{`Invoice`}</inlineCode></li>
          <li parentName="ul">{`Belong to a `}<inlineCode parentName="li">{`Payment`}</inlineCode></li>
          <li parentName="ul">{`has many `}<inlineCode parentName="li">{`ExeternalInvoicePaymentIds`}</inlineCode></li>
          <li parentName="ul">{`has many `}<inlineCode parentName="li">{`Payment Reason Invoice Payments`}</inlineCode><ul parentName="li">
              <li parentName="ul">{`has many `}<inlineCode parentName="li">{`Payment Reasons`}</inlineCode></li>
              <li parentName="ul">{`Attributes:`}<ul parentName="li">
                  <li parentName="ul"><inlineCode parentName="li">{`amount_cents (int)`}</inlineCode>{` `}</li>
                </ul></li>
            </ul></li>
          <li parentName="ul">{`Attributes:`}<ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`paid_amount_cents (int)`}</inlineCode>{` `}</li>
            </ul></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "creation"
    }}>{`Creation`}</h2>
    <p>{`There are 2 ways to create invoice payments:`}</p>
    <ol>
      <li parentName="ol">{`Syncing in via external integration`}</li>
      <li parentName="ol">{`Creation via API endpoint (POST to `}<inlineCode parentName="li">{`/api/partner/v2/invoice_payments`}</inlineCode>{`)`}</li>
    </ol>
    <h2 {...{
      "id": "fetching-an-invoice-payment"
    }}>{`Fetching an invoice payment`}</h2>
    <p>{`Send a GET request to `}<inlineCode parentName="p">{`/partner/v2/invoice_payments/id`}</inlineCode>{`, where id is the id of the requested invoice payment.`}</p>
    <h2 {...{
      "id": "creating-an-invoice-payment"
    }}>{`Creating an invoice payment`}</h2>
    <p>{`Send a POST request to `}<inlineCode parentName="p">{`/api/partner/v2/invoice_payments`}</inlineCode>{`, following this JSON body format:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "invoice_payment": {
        "payment_id": int,
        "invoice_id": int,
        "paid_amount_cents": int,
        "reversal": (optional) boolean,
        "reversal_date": (optional) datetime,
        "payment_reasons_invoice_payments_attributes": (optional array) [
            {
                "payment_reason_id": int,
                "amount_cents": int
            }
        ]
    }
}
`}</code></pre>
    <p>{`The server will respond with the created invoice payment.`}</p>
    <h2 {...{
      "id": "updating-an-invoice-payment"
    }}>{`Updating an invoice payment`}</h2>
    <p>{`Same as creating an invoice payment, but instead direct requests to `}<inlineCode parentName="p">{`/api/partner/v2/invoice_payments/id`}</inlineCode>{`, where id is the id of the invoice payment to update.
Any new payment reason invoice payments will be appended to the ones already present.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      